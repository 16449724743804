import { ThemeOptions } from "@mui/material";
import { createFromBaseTheme } from "@/style/baseTheme";

import logo from '/images/logo.png'
import logoBar from '/images/logoBar.png'

const customConstants: ThemeOptions = {
  palette: {
    primary: {
      main: "#ac0a32",
    },
    secondary: {
      main: "#ac0a32",
    }
  },
  custom: {
    constants: {
      logoImage: logo,
      logoBarImage: logoBar,
      borderRadius: 10,
    }
  }
};

export default createFromBaseTheme(customConstants)

